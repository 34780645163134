<template>
    <div class="container">
        <Intro title="Privacy" :content="paragraphs"></Intro>
        <div class="content">
            <img :src="logo" />
            <div class="info">
                <h2>Privacy & Cookies</h2>
                <p>We don't use cookies, and our website doesn't collect any data about you unless you use our contact form. Our website automatically tracks individual requests, however these are not identifiable and are used exclusively to identify faults on the website.</p>
              <p>When booking appointments, communicating with us, and undergoing treatment we collect information that is required for the task, and store that information securely and in accordance with GDPR.</p>
              <p>We do not share your information with third parties except where it is required as part of your treatment, for instance we may share dental information with a supplier to acquire materials.</p>
              <p>If you find that your information with the surgery is out of date, or if you have any queries about what data we hold about you, please contact us and we will endeavour to answer any questions.</p>
              <p>You have a right to access any personal data we hold about you, to obtain a copy of personal information please contact us.</p>
            </div>
        </div>
	</div>
</template>

<style scoped>
	.content{
		background-color: var(--primary-variant);
	}
	.info{
		color: var(--primary);
		padding: 0 2rem 1rem 2rem;
	}
	.info-2{
		padding: 1rem 2rem;
		color: var(--background);
		background: var(--primary);
	}
	.info-2 > h2{
		margin: 0;
	}
	img{
		padding: 3rem;
	}
	@media screen and (min-width: 1024px){
	.content{
		display: flex;
		flex-wrap: wrap;		
	}
	.info-2, img{
		padding: 2rem var(--padding1);
	}
	.info{
		width: 45%;
		font-size: 1rem;
		padding: 2rem 0;
	}
	.info-2{
		width: 100%;
	}
	img{
		width: 20rem;
	}		
	}
	@media screen and (min-width: 1440px) {
        .info-2, img{
            padding: 2rem var(--padding2);
        }
    }
</style>
<script>
import Intro from '../components/Intro.vue';
import logo from '../assets/light/BSDP_DP_WHITTLESEY_SEAL_LOGO.svg'

export default {
    components: {
    Intro,
    },
    data(){
      return {
        logo,
        paragraphs: ["We don't use cookies, and we don't collect any data about you unless you use our contact form. Here you'll find more information about our privacy policy."],
      }
    }
}

</script>